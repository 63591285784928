import {addClass, removeClass, hasClass, find} from '@elements/dom-utils';
import {onFind, initInScope} from "@elements/init-modules-in-scope";
import cartSidebar from "./checkout/templates/CartSidebar.vue";
import tooltipDirective from "./checkout/directives/tooltip";
import {createApp, ref, nextTick} from 'vue';

export function init () {
    onFind('.vue-cart-sidebar', function(element) {
        let vueApp = createApp({
            components: {
                cartSidebar
            },
            setup() {
                let showSidebar = ref(false);
                const handleClick = () => {
                    showSidebar.value= !showSidebar.value;
                    let sidebar = find('.js-cart-sidebar');
                    if(!hasClass("shown", sidebar)) {
                        addClass("shown", sidebar);
                    } else {
                        if(hasClass("shown", sidebar)) {
                            removeClass("shown", sidebar);
                        }
                    }

                    if(!hasClass("is-open", element)) {
                        addClass("is-open", element);
                    } else {
                        if(hasClass("is-open", element)) {
                            removeClass("is-open", element);
                        }
                    }
                }
                return {
                    showSidebar,
                    handleClick,
                };
            }
        });
        tooltipDirective(vueApp);
        vueApp.mount(element);
    });
}